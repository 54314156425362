import styled from "styled-components";
import colors from "../../../constants/colors";

export const IconWrapper = styled("div")({
  position: "relative",
  display: "inline-block",
  width: "100px",
  ".MuiSvgIcon-root": {
    fontSize: "4rem",
  },
});

export const CogIconWrapper = styled("div")({
  position: "absolute",
  top: "40px",
  left: 0,

  ".MuiSvgIcon-root": {
    fontSize: "1.5rem",
    backgroundColor: colors.WHITE,
    borderRadius: "50%",
    padding: "1px",
    color: colors.BLACK,
  },
});
