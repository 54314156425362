import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import KLButton from "../../../components/KLButton";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import {
  ChipServiceDescription,
  chipSx,
  ChipWrapper,
  Container,
  InfoText,
  Subtitle,
  Title,
} from "../../styles";
import { useStep } from "../../../context/StepContext";
import Chip from "@mui/material/Chip";
import { isEqual } from "lodash";
import { AvailabilityType } from "../../../constants/types";

const availabilityOptions = [
  {
    value: AvailabilityType.Weekly,
    label: "אני רוצה לתכנן זמינות כל שבוע",
    elaborate: "נציע לכם תאריכים ושעות של זמינות כל שבוע",
  },
  {
    value: AvailabilityType.BiWeekly,
    label: "אני רוצה לתכנן זמינות כל שבועיים",
    elaborate: "נציע לכם תאריכים ושעות של זמינות כל שבועיים",
  },
  {
    value: AvailabilityType.WeeklyRecurring,
    label: "אני רוצה לתכנן זמינות קבועה",
    elaborate: "נציע לכם תאריכים ושעות לזמינות קבועה",
  },
];

const AuthorizeScreen: React.FC = () => {
  const { incrementStep, onRedirect } = useStep();
  const { businessName, ownerName, phone, email, category, services } =
    useSelector((state: RootState) => state.businesses);

  const [selectedAvailability, setSelectedAvailability] = useState<
    string | null
  >(null);

  const handleAuthorize = () => {
    const formData = {
      ownerName,
      businessName,
      phone,
      email,
      category,
      services,
      availability: selectedAvailability,
    };
    const state = encodeURIComponent(JSON.stringify(formData));

    incrementStep();
    onRedirect(true);

    window.location.href = `${process.env.REACT_APP_BACKEND_API}/api/auth/authorize-google-calendar?state=${state}`;
  };

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={3} />
      <Title>אישור גישה ליומן Google</Title>
      <Subtitle>למה צריך את ההרשאה?</Subtitle>
      <InfoText>
        נדרש אישור גישה ליומן Google כדי לנהל את התורים שלך אוטומטית דרך המערכת
        שלנו. זה יאפשר לנו להוסיף ולשנות תורים עבורך.
      </InfoText>

      <Title>בחרו את אופן הזמינות שלכם</Title>

      {availabilityOptions.map((availability) => (
        <ChipWrapper key={availability.value}>
          <Chip
            sx={chipSx(isEqual(selectedAvailability, availability.value))}
            label={
              <>
                <ChipServiceDescription>
                  {availability.label}
                </ChipServiceDescription>
                <span>{availability.elaborate}</span>
              </>
            }
            color={
              selectedAvailability === availability.value
                ? "primary"
                : "default"
            }
            onClick={() => setSelectedAvailability(availability.value)}
            style={{ padding: "20px", width: "100%" }}
          />
        </ChipWrapper>
      ))}

      <KLButton
        onClick={handleAuthorize}
        title="אשר גישה"
        disabled={!selectedAvailability}
      />
    </Container>
  );
};

export default AuthorizeScreen;
