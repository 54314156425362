import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useStep } from "../../context/StepContext";
import { AppDispatch, RootState } from "../../store";
import Chip from "@mui/material/Chip";
import { ServicesList } from "./styles";
import KLButton from "../../components/KLButton";
import { fetchServices } from "../../store/duckers/services/thunk";
import { setCustomerService } from "../../store/duckers/customers/slice";
import {
  ChipServiceDescription,
  chipSx,
  ChipWrapper,
  Container,
  InfoText,
  Subtitle,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import KLPleaseWait from "../../components/KLPleaseWait";
import { getDurationLabel } from "../../constants/slots/duration";
import { ServiceWithId } from "../../constants/types";
import { cancellationOptions } from "../../utils";

const getCancellationLabel = (cancellationPolicy: number | undefined) => {
  if (cancellationPolicy === undefined) return null;
  const option = cancellationOptions.find(
    (opt) => opt.value === cancellationPolicy,
  );
  return option ? `ביטול ${option.label}` : null;
};

const CustomerServicesScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();

  const { customerService: storedSelectedService } = useSelector(
    (state: RootState) => state.customers,
  );

  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    storedSelectedService || null,
  );
  const { businessId } = useParams<{ businessId: string }>();

  const { fetchingServices, services } = useSelector(
    (state: RootState) => state.services,
  );

  useEffect(() => {
    if (!businessId) {
      return;
    }
    dispatch(fetchServices(businessId));
  }, [businessId]);

  const handleServiceSelect = (service: ServiceWithId) => {
    setSelectedService((prevSelected) =>
      isEqual(prevSelected, service) ? null : service,
    );
  };

  const handleConfirm = () => {
    if (!selectedService) {
      return;
    }
    dispatch(setCustomerService(selectedService));
    incrementStep();
    navigate(`/${businessId}/slots`);
  };

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={2} />
      {fetchingServices ? (
        <KLPleaseWait text="בודקים אילו שירותים יש לעסק להציע" />
      ) : (
        <>
          {!services.length ? (
            <>
              <Title>אין דרך קלה להגיד את זה אבל...</Title>
              <Subtitle>משהו לא עובד :(</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
            </>
          ) : (
            <>
              <Title>מה בא לכם לקבוע?</Title>
              <InfoText>כאן בוחרים את סוג השירות שרוצים לקבל מהעסק</InfoText>
              <ServicesList>
                {services.map((service) => (
                  <ChipWrapper key={service.description}>
                    <Chip
                      sx={chipSx(isEqual(selectedService, service))}
                      label={
                        <>
                          <ChipServiceDescription>
                            {service.description}
                          </ChipServiceDescription>
                          {!service.showWithoutPrice && (
                            <span>{service.price} ש״ח | </span>
                          )}
                          {getDurationLabel(service.duration)}
                          {service.cancellationPolicy && (
                            <>
                              {" | "}
                              {getCancellationLabel(service.cancellationPolicy)}
                            </>
                          )}
                        </>
                      }
                      color={
                        isEqual(selectedService, service)
                          ? "primary"
                          : "default"
                      }
                      onClick={() => handleServiceSelect(service)}
                      style={{ padding: "20px", width: "100%" }}
                    />
                  </ChipWrapper>
                ))}
              </ServicesList>
              <KLButton
                title={"ממשיכים"}
                onClick={handleConfirm}
                disabled={!selectedService}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CustomerServicesScreen;
