import React, { useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KLButton from "../../../components/KLButton";
import KLTextInput from "../../../components/KLTextInput";
import {
  ServicesDisclaimer,
  CheckboxWrapper,
  reactSelectStyles,
  ServicesFieldWrapper,
} from "./styles";
import { Service } from "../../../constants/types";
import { AppDispatch, RootState } from "../../../store";
import { setBusinessServices } from "../../../store/duckers/businesses/slice";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import { Container, InfoText, Title, FormWrapper } from "../../styles";
import { useStep } from "../../../context/StepContext";
import { durationOptions } from "../../../constants/slots/duration";
import { cancellationOptions } from "../../../utils";

const ServicesSetupScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const storedServices = useSelector(
    (state: RootState) => state.businesses.services,
  );
  const [services, setServices] = useState<Service[]>(
    storedServices && storedServices.length > 0
      ? storedServices
      : [{ description: "", price: "", duration: 0, showWithoutPrice: false }],
  );
  const [isPriceValid, setIsPriceValid] = useState<boolean[]>(
    storedServices && storedServices.length > 0
      ? storedServices.map(
          (service) => service.showWithoutPrice || !!service.price,
        )
      : [false],
  );

  useEffect(() => {
    if (storedServices && storedServices.length > 0) {
      setServices(storedServices);
    }
  }, [storedServices]);

  const handleAddRow = () => {
    setServices([
      ...services,
      { description: "", price: "", duration: 0, showWithoutPrice: false },
    ]);
    setIsPriceValid([...isPriceValid, true]);
  };

  const handleDeleteRow = (index: number) => {
    const updatedServices = services.filter(
      (_, serviceIndex) => serviceIndex !== index,
    );
    setServices(updatedServices);
    const updatedPriceValid = isPriceValid.filter(
      (_, priceIndex) => priceIndex !== index,
    );
    setIsPriceValid(updatedPriceValid);
  };

  const handleServiceChange = <K extends keyof Service>(
    index: number,
    field: K,
    value: Service[K],
  ) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  const handlePriceValidation = (index: number, isValid: boolean) => {
    const updatedPriceValid = [...isPriceValid];
    updatedPriceValid[index] = isValid;
    setIsPriceValid(updatedPriceValid);
  };

  const isFormValid = useMemo(() => {
    return (
      services.length > 0 &&
      services.every(
        (service, index) =>
          service.description &&
          service.duration > 0 &&
          service.cancellationPolicy !== undefined &&
          service.cancellationPolicy >= 0 &&
          (service.showWithoutPrice || isPriceValid[index]),
      )
    );
  }, [services, isPriceValid]);

  const handleSubmit = () => {
    if (isFormValid) {
      dispatch(setBusinessServices(services));
      incrementStep();
      navigate("/onboarding/authorize");
    }
  };

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={2} />
      <Title>מה יש לכם להציע?</Title>
      <InfoText>כאן מוסיפים את השירותים שאתם מציעים ללקוחות שלכם</InfoText>

      <FormWrapper>
        {services.map((service, index) => (
          <div key={index}>
            <ServicesFieldWrapper>
              <KLTextInput
                type="text"
                placeholder="מהו השירות אותו תרצו להציע?"
                value={service.description}
                onChange={(e) =>
                  handleServiceChange(index, "description", e.target.value)
                }
                maxNumOfCharacters={20}
                required
              />
            </ServicesFieldWrapper>
            <ServicesFieldWrapper>
              <KLTextInput
                type="number"
                placeholder="כמה תרצו לגבות עבור מתן השירות? (ש״ח)"
                value={service.price}
                onChange={(e) =>
                  handleServiceChange(index, "price", e.target.value)
                }
                disabled={service.showWithoutPrice}
                onValidationChange={(isValid) =>
                  handlePriceValidation(index, isValid)
                }
                required={!service.showWithoutPrice}
              />
            </ServicesFieldWrapper>
            <ServicesFieldWrapper>
              <Select
                options={durationOptions}
                value={durationOptions.find(
                  (option) => option.value === service.duration,
                )}
                onChange={(selectedOption) =>
                  handleServiceChange(
                    index,
                    "duration",
                    selectedOption ? selectedOption.value : 0,
                  )
                }
                placeholder="כמה זמן עבור מתן השירות?"
                styles={reactSelectStyles}
                isClearable={false}
              />
            </ServicesFieldWrapper>
            <ServicesFieldWrapper>
              <Select
                options={cancellationOptions}
                value={cancellationOptions.find(
                  (option) => option.value === service.cancellationPolicy,
                )}
                onChange={(selectedOption) =>
                  handleServiceChange(
                    index,
                    "cancellationPolicy",
                    selectedOption ? selectedOption.value : 0,
                  )
                }
                placeholder="כמה זמן לפני אפשר לבטל?"
                styles={reactSelectStyles}
                isClearable={false}
              />
            </ServicesFieldWrapper>

            <ServicesFieldWrapper>
              <CheckboxWrapper>
                <label>
                  <input
                    type="checkbox"
                    checked={service.showWithoutPrice}
                    onChange={(e) =>
                      handleServiceChange(
                        index,
                        "showWithoutPrice",
                        e.target.checked,
                      )
                    }
                  />
                  הצג שירות זה ללא מחיר
                </label>
              </CheckboxWrapper>
            </ServicesFieldWrapper>
            <ServicesFieldWrapper>
              <KLButton
                title={"התחרטתי, רוצה למחוק את השירות הזה"}
                onClick={() => handleDeleteRow(index)}
                warning
              />
            </ServicesFieldWrapper>
          </div>
        ))}
        <ServicesDisclaimer>
          מחירי השירותים הינם בשקלים חדשים וכוללים מע״מ.
        </ServicesDisclaimer>
        <KLButton
          title={"הוסיפו עוד שירותים"}
          onClick={handleAddRow}
          secondary
        />
        <KLButton
          title={"הבא"}
          onClick={handleSubmit}
          disabled={!isFormValid}
        />
      </FormWrapper>
    </Container>
  );
};

export default ServicesSetupScreen;
