import styled from "styled-components";
import colors from "../../constants/colors";
import { Link } from "react-router-dom";

export const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "24px",
});

export const LogoLink = styled(Link)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "inherit",
});

export const Logo = styled("img")({
  height: "100px",
  cursor: "pointer",
});

export const AppName = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "18px",
  color: colors.BLACK,
  marginTop: "10px",
  textDecoration: "none",
});
