import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  DeleteButton,
  DeleteButtonWrapper,
  DropdownWrapper,
  reactSelectStyles,
  SlotContent,
  SlotWrapper,
  TimeRow,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { createSlots } from "../../../store/duckers/slots/thunk";
import KLButton from "../../../components/KLButton";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import { Container, InfoText, Subtitle, Title } from "../../styles";
import { useStep } from "../../../context/StepContext";
import KLTrashCanIcon from "../../../components/icons/KLTrashCanIcon";
import {
  dayMapping,
  daysOfWeek,
  generateDateDayOptions,
  generateTimeOptions,
} from "../../../utils";
import { format } from "date-fns";
import { AvailabilityType } from "../../../constants/types";

const SlotsSetupScreen: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { incrementStep, setStep } = useStep();
  const { creatingSlots, createSlotsSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );

  const [slots, setSlots] = useState([
    { dateDay: "", date: "", day: "", startTime: "", endTime: "" },
  ]);
  const [availabilityType, setAvailabilityType] =
    useState<AvailabilityType | null>(null);
  const [dateDayOptions, setDateDayOptions] = useState<
    { value: string; label: string; date: string; day: string }[]
  >([]);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const businessId = searchParams.get("businessId");

  const availableSlots = useMemo(() => {
    const availableSlotsString = searchParams.get("availableSlots");
    return availableSlotsString
      ? JSON.parse(decodeURIComponent(availableSlotsString))
      : null;
  }, [searchParams]);

  const handleAddRow = () => {
    setSlots([
      ...slots,
      { dateDay: "", date: "", day: "", startTime: "", endTime: "" },
    ]);
  };

  const handleDeleteRow = (index: number) => {
    const updatedSlots = slots.filter((_, slotIndex) => slotIndex !== index);
    setSlots(updatedSlots);
  };

  const handleSlotChange = (
    index: number,
    field: keyof (typeof slots)[number],
    value: string,
  ) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot, i) => {
        if (i !== index) return slot;

        if (field === "dateDay") {
          const selectedOption = dateDayOptions.find(
            (option) => option.value === value,
          );
          return {
            ...slot,
            dateDay: value,
            date: selectedOption?.date || "",
            day: selectedOption?.day || "",
          };
        }

        return { ...slot, [field]: value };
      }),
    );
  };

  const getSlotIdentifier = (slot: (typeof slots)[number]) => {
    return availabilityType === AvailabilityType.WeeklyRecurring
      ? slot.day
      : slot.dateDay;
  };

  const getDisabledTimesForSlot = (
    slotIdentifier: string,
    currentIndex: number,
    timeType: "startTime" | "endTime",
  ) => {
    return slots
      .slice(0, currentIndex)
      .filter((slot) => getSlotIdentifier(slot) === slotIdentifier)
      .map((slot) => slot[timeType]);
  };

  const getTimesForSlot = (
    slotIdentifier: string,
    selectedStartTime: string,
    isEndTime: boolean,
    currentIndex: number,
  ) => {
    const slot = slots[currentIndex];
    const dayName = slot.day;
    const availableTimes = dayName === "שישי" ? fridayTimes : weekdayTimes;

    const previousEndTime = slots
      .slice(0, currentIndex)
      .filter((s) => getSlotIdentifier(s) === slotIdentifier)
      .map((s) => s.endTime)
      .reduce((latest, time) => (time > latest ? time : latest), "00:00");

    const startTimes = availableTimes.filter(
      (time) => time.value >= previousEndTime,
    );

    return isEndTime && selectedStartTime
      ? startTimes.filter((time) => time.value > selectedStartTime)
      : startTimes;
  };

  const isFormValid = useMemo(() => {
    return (
      slots.length > 0 &&
      slots.every(
        (slot) =>
          slot.startTime &&
          slot.endTime &&
          (availabilityType === AvailabilityType.WeeklyRecurring
            ? slot.day
            : slot.dateDay),
      )
    );
  }, [slots, availabilityType]);

  const weekdayTimes = useMemo(
    () => generateTimeOptions("07:00", "22:00", 30),
    [],
  );
  const fridayTimes = useMemo(
    () => generateTimeOptions("08:00", "17:00", 30),
    [],
  );

  const handleSubmit = () => {
    if (isFormValid && businessId && availabilityType) {
      const convertedSlots = slots.map((slot) => ({
        day: slot.day,
        date: slot.date,
        startTime: slot.startTime,
        endTime: slot.endTime,
      }));
      dispatch(
        createSlots({ businessId, availabilityType, slots: convertedSlots }),
      );
    }
  };

  useEffect(() => {
    setStep(4);
  }, [setStep]);

  useEffect(() => {
    if (createSlotsSucceeded) {
      incrementStep();
      navigate(`/onboarding/success?businessId=${businessId}`);
    }
  }, [createSlotsSucceeded]);

  useEffect(() => {
    if (availableSlots) {
      const { availabilityType, slots: receivedSlots } = availableSlots;
      setAvailabilityType(availabilityType);

      if (
        availabilityType === AvailabilityType.Weekly ||
        AvailabilityType.BiWeekly
      ) {
        const weeksAhead = availabilityType === AvailabilityType.Weekly ? 1 : 2;
        const options = generateDateDayOptions(weeksAhead);
        setDateDayOptions(options);
      }

      const populatedSlots = receivedSlots.map(
        (slot: {
          day: string;
          date?: string;
          startTime: string;
          endTime: string;
        }) => ({
          dateDay: slot.date
            ? `${dayMapping[slot.day]} - ${format(new Date(slot.date), "dd/MM/yy")}`
            : "",
          date: slot.date || "",
          day: dayMapping[slot.day] || "",
          startTime: slot.startTime,
          endTime: slot.endTime,
        }),
      );
      setSlots(populatedSlots);
    }
  }, [availableSlots]);

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={4} />
      <Title>מתי תרצו לקבל לקוחות?</Title>
      {availabilityType === AvailabilityType.Weekly && (
        <Subtitle>
          אלו הימים והשעות שזיהינו שבמהלכם אתם פנויים בשבוע הקרוב
        </Subtitle>
      )}
      {availabilityType === AvailabilityType.BiWeekly && (
        <Subtitle>
          אלו הימים והשעות שזיהינו שבמהלכם אתם פנויים בשבועיים הקרובים
        </Subtitle>
      )}
      {availabilityType === AvailabilityType.WeeklyRecurring && (
        <Subtitle>אלו הימים והשעות שזיהינו שבמהלכם אתם פנויים כל שבוע</Subtitle>
      )}
      <InfoText>אפשר להוסיף, למחוק ולשנות!</InfoText>
      {slots.map((slot, index) => (
        <SlotWrapper key={index}>
          <SlotContent>
            {availabilityType !== AvailabilityType.WeeklyRecurring && (
              <DropdownWrapper>
                <Select
                  options={dateDayOptions}
                  value={dateDayOptions.find(
                    (option) => option.value === slot.dateDay,
                  )}
                  onChange={(selectedOption) =>
                    handleSlotChange(
                      index,
                      "dateDay",
                      (selectedOption as any).value,
                    )
                  }
                  placeholder="בחרו יום ותאריך"
                  styles={reactSelectStyles()}
                />
              </DropdownWrapper>
            )}
            {availabilityType === AvailabilityType.WeeklyRecurring && (
              <DropdownWrapper>
                <Select
                  options={daysOfWeek}
                  value={daysOfWeek.find((option) => option.value === slot.day)}
                  onChange={(selectedOption) =>
                    handleSlotChange(
                      index,
                      "day",
                      (selectedOption as any).value,
                    )
                  }
                  placeholder="בחרו יום"
                  styles={reactSelectStyles()}
                />
              </DropdownWrapper>
            )}
            <TimeRow>
              <DropdownWrapper>
                <Select
                  options={getTimesForSlot(
                    getSlotIdentifier(slot),
                    "",
                    false,
                    index,
                  ).filter(
                    (option) =>
                      !getDisabledTimesForSlot(
                        getSlotIdentifier(slot),
                        index,
                        "startTime",
                      ).includes(option.value),
                  )}
                  value={weekdayTimes.find(
                    (option) => option.value === slot.startTime,
                  )}
                  onChange={(selectedOption) =>
                    handleSlotChange(
                      index,
                      "startTime",
                      (selectedOption as any).value,
                    )
                  }
                  placeholder="שעת התחלה"
                  styles={reactSelectStyles()}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Select
                  options={getTimesForSlot(
                    getSlotIdentifier(slot),
                    slot.startTime,
                    true,
                    index,
                  ).filter(
                    (option) =>
                      !getDisabledTimesForSlot(
                        getSlotIdentifier(slot),
                        index,
                        "endTime",
                      ).includes(option.value),
                  )}
                  value={weekdayTimes.find(
                    (option) => option.value === slot.endTime,
                  )}
                  onChange={(selectedOption) =>
                    handleSlotChange(
                      index,
                      "endTime",
                      (selectedOption as any).value,
                    )
                  }
                  placeholder="שעת סיום"
                  styles={reactSelectStyles()}
                />
              </DropdownWrapper>
            </TimeRow>
          </SlotContent>
          <DeleteButtonWrapper>
            <DeleteButton onClick={() => handleDeleteRow(index)}>
              <KLTrashCanIcon />
            </DeleteButton>
          </DeleteButtonWrapper>
        </SlotWrapper>
      ))}
      <KLButton
        title={"הוסיפו עוד ימים ושעות"}
        onClick={handleAddRow}
        secondary
      />
      <KLButton
        title={"כבר מסיימים"}
        onClick={handleSubmit}
        disabled={!isFormValid || creatingSlots}
      />
    </Container>
  );
};

export default SlotsSetupScreen;
