import React from "react";
import { BusinessIconWrapper } from "./styles";
import { WorkHistory } from "@mui/icons-material";

const KLBusinessIcon: React.FC = () => {
  return (
    <BusinessIconWrapper>
      <WorkHistory />
    </BusinessIconWrapper>
  );
};

export default KLBusinessIcon;
