import styled from "styled-components";
import colors from "../../constants/colors";

export const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  textAlign: "right",
});

export const Content = styled("p")({
  fontSize: "16px",
  color: colors.GREY_7,
  lineHeight: "1.5",
});
