import { combineReducers } from "redux";
import slotsReducer from "./duckers/slots/slice";
import customersReducer from "./duckers/customers/slice";
import businessesReducer from "./duckers/businesses/slice";
import categoriesReducer from "./duckers/categories/slice";
import servicesReducer from "./duckers/services/slice";
import shortLinksReducer from "./duckers/shortLinks/slice";
import tokensReducer from "./duckers/tokens/slice";

const rootReducer = combineReducers({
  slots: slotsReducer,
  customers: customersReducer,
  businesses: businessesReducer,
  categories: categoriesReducer,
  services: servicesReducer,
  shortLinks: shortLinksReducer,
  tokens: tokensReducer,
});

export default rootReducer;
