import React from "react";
import { ReminderIconWrapper } from "./styles";
import { NotificationsActive } from "@mui/icons-material";

const KLReminderIcon: React.FC = () => {
  return (
    <ReminderIconWrapper>
      <NotificationsActive />
    </ReminderIconWrapper>
  );
};

export default KLReminderIcon;
