import React from "react";
import { CircularProgress } from "@mui/material";
import { Button } from "./styles";

interface KLButtonProps {
  title: string;
  onClick?: () => void;
  secondary?: boolean;
  warning?: boolean;
  noWidth?: boolean;
  noMarginTop?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const KLButton: React.FC<KLButtonProps> = ({
  onClick,
  disabled = false,
  loading = false,
  secondary = false,
  warning = false,
  noWidth = false,
  noMarginTop = false,
  title,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      $secondary={secondary}
      $warning={warning}
      $noWidth={noWidth}
      $noMarginTop={noMarginTop}
    >
      {loading ? <CircularProgress size={24} /> : title}
    </Button>
  );
};

export default KLButton;
