import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCategories } from "./thunk";

interface CategoriesState {
  categories: string[];
  fetchingCategories: boolean;
  error: string | null;
}

const initialState: CategoriesState = {
  categories: [],
  fetchingCategories: false,
  error: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.fetchingCategories = true;
        state.error = null;
      })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.categories = action.payload;
          state.fetchingCategories = false;
        },
      )
      .addCase(
        fetchCategories.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.error = action.payload ?? "Failed to fetch categories";
          state.fetchingCategories = false;
        },
      );
  },
});

export default categoriesSlice.reducer;
