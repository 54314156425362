import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import { LinkWrapper } from "./styles";
import { Container, InfoText, Subtitle, Title } from "../../styles";
import { useStep } from "../../../context/StepContext";
import KLButton from "../../../components/KLButton";

const OnboardingSuccessScreen: React.FC = () => {
  const navigate = useNavigate();
  const { resetSteps } = useStep();
  const location = useLocation();
  const [copied, setCopied] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const link = `https://kavanu.li/${businessId}`;

  const handleCopyLink = (): void => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleGoHome = (): void => {
    resetSteps();
    navigate("/");
  };

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={5} />
      <Title>יש לנו את זה!</Title>
      <Subtitle>ההרשמה של העסק שלכם הסתיימה בהצלחה!</Subtitle>
      <InfoText>
        עכשיו שולחים את הקישור הבא ללקוחות שלכם, והם יקבעו את התורים:
      </InfoText>
      <LinkWrapper>{link}</LinkWrapper>
      <KLButton
        onClick={handleCopyLink}
        title={copied ? "קישור הועתק!" : "העתק קישור"}
        secondary
      />
      <KLButton onClick={handleGoHome} title="חזרה לדף הבית" />
    </Container>
  );
};

export default OnboardingSuccessScreen;
