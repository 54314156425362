import axios from "axios";

class ShortLinkService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async fetchOriginalUrl(shortCode: string): Promise<{ originalUrl: string }> {
    try {
      const response = await axios.get<{ originalUrl: string }>(
        `${this.baseApiUrl}/api/short-links/${shortCode}`,
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch original URL");
    }
  }
}

export const shortLinkService = new ShortLinkService();
