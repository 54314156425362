import React from "react";
import KLProgressBar from "../KLProgressBar";

type KLCustomerBookingProgressProps = {
  currentStep: number;
};

const customerLabels = ["קצת פרטים", "מה בא לכם", "מתי בא לכם", "קבענו לי!"];

const KLCustomerBookingProgress: React.FC<KLCustomerBookingProgressProps> = ({
  currentStep,
}) => {
  return (
    <KLProgressBar
      currentStep={currentStep}
      totalSteps={customerLabels.length}
      labels={customerLabels}
    />
  );
};

export default KLCustomerBookingProgress;
