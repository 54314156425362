import styled from "styled-components";
import colors from "../constants/colors";
import { SxProps, Theme } from "@mui/material";

export const Container = styled("div")({
  backgroundColor: colors.BACKGROUND,
  borderRadius: "15px",
  padding: "0 1rem 1rem 1rem",
  color: colors.BLACK,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
  textAlign: "center",
  direction: "rtl",
});

export const Title = styled("div")({
  fontSize: "24px",
  fontWeight: "bold",
  color: colors.BLACK,
  marginBottom: "1rem",
});

export const Subtitle = styled("h2")({
  fontSize: "20px",
  color: colors.GREY_7,
  marginBottom: "1rem",
  maxWidth: "300px",
  textAlign: "center",
});

export const InfoText = styled("p")({
  fontSize: "16px",
  color: colors.GREY_6,
  marginBottom: "1.5rem",
  maxWidth: "300px",
});

export const ContentWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.5rem",
});

export const Content = styled("p")({
  fontSize: "18px",
  marginBottom: "1rem",
  color: colors.GREY_6,
});

export const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
});

export const FieldWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
});

export const PleaseWaitContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const chipSx = (selected: boolean): SxProps<Theme> => ({
  backgroundColor: selected ? colors.PURPLE_LIGHT : colors.GREY_2,
  color: selected ? colors.WHITE : colors.BLACK,
  "&:hover": {
    backgroundColor: selected ? colors.PURPLE_LIGHT : colors.GREY_2,
  },
});

export const ChipWrapper = styled("div")({
  width: "100%",
});

export const ChipServiceDescription = styled("div")({
  fontSize: "16px",
  fontWeight: 700,
});
