import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchOriginalUrl } from "./thunk";

interface ShortLinkState {
  originalUrl: string | null;
  fetchingOriginalUrl: boolean;
  error: string | null;
}

const initialState: ShortLinkState = {
  originalUrl: null,
  fetchingOriginalUrl: false,
  error: null,
};

const shortLinksSlice = createSlice({
  name: "shortLinks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOriginalUrl.pending, (state) => {
        state.fetchingOriginalUrl = true;
        state.originalUrl = null;
        state.error = null;
      })
      .addCase(
        fetchOriginalUrl.fulfilled,
        (state, action: PayloadAction<{ originalUrl: string }>) => {
          state.fetchingOriginalUrl = false;
          state.originalUrl = action.payload.originalUrl;
        },
      )
      .addCase(fetchOriginalUrl.rejected, (state, action) => {
        state.fetchingOriginalUrl = false;
        state.error = action.payload || "Failed to fetch the original URL";
      });
  },
});

export default shortLinksSlice.reducer;
