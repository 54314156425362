import axios from "axios";
import { ServiceWithId } from "../constants/types";

class ServicesService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async fetchServices(businessId: string): Promise<ServiceWithId[]> {
    try {
      const response = await axios.get<ServiceWithId[]>(
        `${this.baseApiUrl}/api/${businessId}/services`,
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );

      const services = response.data;

      // Ensure the response structure is valid (i.e., an array of services)
      if (!Array.isArray(services)) {
        throw new Error("Invalid response structure");
      }

      return services;
    } catch (error) {
      throw new Error("Failed to fetch services");
    }
  }
}

export const servicesService = new ServicesService();
