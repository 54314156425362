import { createAsyncThunk } from "@reduxjs/toolkit";
import { AvailabilityType, Slot } from "../../../constants/types";
import { slotsService } from "../../../services/slotsService";

export const createSlots = createAsyncThunk<
  void,
  { businessId: string; availabilityType: AvailabilityType; slots: any[] },
  { rejectValue: string }
>(
  "slots/createSlots",
  async ({ businessId, availabilityType, slots }, { rejectWithValue }) => {
    try {
      await slotsService.createSlots(businessId, availabilityType, slots);
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchSlots = createAsyncThunk<
  { availableSlots: Slot[]; unavailableSlots: Slot[] },
  { businessId: string; duration: number },
  { rejectValue: string }
>("slots/fetchSlots", async ({ businessId, duration }, { rejectWithValue }) => {
  try {
    return await slotsService.fetchSlots(businessId, duration);
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});

export const bookSlot = createAsyncThunk<
  void,
  {
    businessId: string;
    eventId: string;
    customerName: string;
    customerPhone: string;
    serviceId: number;
    startTime: string;
    endTime: string;
  },
  { rejectValue: string }
>(
  "slots/bookSlot",
  async (
    {
      businessId,
      eventId,
      customerName,
      customerPhone,
      serviceId,
      startTime,
      endTime,
    },
    { rejectWithValue },
  ) => {
    try {
      await slotsService.bookSlot(
        businessId,
        eventId,
        customerName,
        customerPhone,
        serviceId,
        startTime,
        endTime,
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const cancelSlot = createAsyncThunk<
  string,
  { businessId: string; slotId: string }
>(
  "slots/requestSlotCancellation",
  async ({ businessId, slotId }, { rejectWithValue }) => {
    try {
      await slotsService.cancelSlot(businessId, slotId);
      return slotId;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
