import React from "react";
import KLProgressBar from "../KLProgressBar";

type KLBusinessOnboardingProgressProps = {
  currentStep: number;
};

const businessLabels = [
  "קצת פרטים",
  "מה מציעים",
  "גישה ליומן",
  "מתי פנויים",
  "קבענו לי!",
];

const KLBusinessOnboardingProgress: React.FC<
  KLBusinessOnboardingProgressProps
> = ({ currentStep }) => {
  return (
    <KLProgressBar
      currentStep={currentStep}
      totalSteps={businessLabels.length}
      labels={businessLabels}
    />
  );
};

export default KLBusinessOnboardingProgress;
