import React, { createContext, useContext, useState, ReactNode } from "react";

interface StepContextProps {
  currentStep: number;
  incrementStep: () => void;
  setStep: (step: number) => void;
  resetSteps: () => void;
  isRedirecting: boolean;
  onRedirect: (value: boolean) => void;
  maxStepReached: number;
}

const StepContext = createContext<StepContextProps | undefined>(undefined);

export const useStep = (): StepContextProps => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepProvider");
  }
  return context;
};

export const StepProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [maxStepReached, setMaxStepReached] = useState<number>(1); // Track the highest step
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const incrementStep = () => {
    setCurrentStep((prev) => prev + 1);
    setMaxStepReached((prev) => Math.max(prev, currentStep + 1));
  };

  const setStep = (step: number) => {
    setCurrentStep(step);
    setMaxStepReached((prev) => Math.max(prev, step));
  };

  const resetSteps = () => {
    setCurrentStep(1);
    setMaxStepReached(1); // Reset max step as well
    setIsRedirecting(false);
  };

  const onRedirect = (value: boolean) => {
    setIsRedirecting(value);
  };

  return (
    <StepContext.Provider
      value={{
        currentStep,
        incrementStep,
        setStep,
        resetSteps,
        isRedirecting,
        onRedirect,
        maxStepReached,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
