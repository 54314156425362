import styled from "styled-components";
import colors from "../../constants/colors";

export const FooterContainer = styled("div")({
  textAlign: "center",
  marginTop: "auto",
  padding: "1rem",
  fontSize: "12px",
  color: colors.GREY_7,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Divider = styled("span")({
  margin: "0 0.5rem",
  fontSize: "1rem",
  color: colors.GREY_7,
});
