import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  ChipWrapper,
  ConfirmationSection,
  Container,
  DaySelection,
  MonthSelection,
  SlotSummary,
  TimeSlots,
} from "./styles";
import Chip from "@mui/material/Chip";
import { DateTime } from "luxon";
import { bookSlot, fetchSlots } from "../../store/duckers/slots/thunk";
import KLButton from "../../components/KLButton";
import { chipSx, InfoText, Subtitle, Title } from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import KLPleaseWait from "../../components/KLPleaseWait";

const CustomerSlotsScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { bookingSlot, bookSlotSucceeded, fetchingSlots, availableSlots } =
    useSelector((state: RootState) => state.slots);
  const { customerName, customerPhone, customerService, business } =
    useSelector((state: RootState) => state.customers);
  const { businessId } = useParams<{ businessId: string }>();
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [selectedDayLabel, setSelectedDayLabel] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDisplayTime, setSelectedDisplayTime] = useState<string | null>(
    null,
  );
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const daySectionRef = useRef<HTMLDivElement | null>(null);
  const timeSectionRef = useRef<HTMLDivElement | null>(null);
  const confirmationSectionRef = useRef<HTMLDivElement | null>(null);

  const getAvailableMonths = (): string[] => {
    const monthsSet = new Set<string>();
    availableSlots.forEach((slot) => {
      const dateTime = DateTime.fromISO(slot.start, {
        zone: "Asia/Jerusalem",
      }).setLocale("he");
      const monthName = dateTime.toFormat("LLLL");
      monthsSet.add(monthName);
    });
    return Array.from(monthsSet);
  };

  const getAvailableDays = (
    monthName: string,
  ): { date: string; label: string }[] => {
    const filteredSlots = availableSlots.filter((slot) => {
      const dateTime = DateTime.fromISO(slot.start, {
        zone: "Asia/Jerusalem",
      }).setLocale("he");
      const slotMonthName = dateTime.toFormat("LLLL");
      return slotMonthName === monthName;
    });

    const dayMap = new Map<string, { date: string; label: string }>();

    filteredSlots.forEach((slot) => {
      const dateTime = DateTime.fromISO(slot.start, {
        zone: "Asia/Jerusalem",
      }).setLocale("he");
      const dateKey = dateTime.toISODate();
      const label = dateTime.toFormat("cccc dd/MM");
      if (dateKey && !dayMap.has(dateKey)) {
        dayMap.set(dateKey, { date: dateKey, label });
      }
    });

    return Array.from(dayMap.values());
  };

  const getAvailableTimes = (dateKey: string) => {
    return availableSlots
      .filter((slot) => {
        const dateTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        });
        return dateTime.toISODate() === dateKey;
      })
      .map((slot) => {
        const startTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        });
        const endTime = DateTime.fromISO(slot.end, {
          zone: "Asia/Jerusalem",
        });
        return {
          startTime: slot.start,
          endTime: slot.end,
          displayTime: `${startTime.toFormat("HH:mm")} - ${endTime.toFormat(
            "HH:mm",
          )}`,
          eventId: slot.id,
        };
      });
  };

  const months = getAvailableMonths();
  const days = selectedMonth ? getAvailableDays(selectedMonth) : [];
  const times = selectedDay ? getAvailableTimes(selectedDay) : [];

  const handleMonthSelect = (month: string) => {
    setSelectedMonth(month);
    setSelectedDay(null);
    setSelectedDayLabel(null);
    setSelectedTime(null);
  };

  const handleDaySelect = (dayObj: { date: string; label: string }) => {
    setSelectedDay(dayObj.date);
    setSelectedDayLabel(dayObj.label);
    setSelectedTime(null);
  };

  const handleTimeSelect = (
    startTime: string,
    endTime: string,
    displayTime: string,
    eventId: string,
  ) => {
    setSelectedTime(`${startTime} - ${endTime}`);
    setSelectedDisplayTime(displayTime);
    setSelectedEventId(eventId);
  };

  const handleConfirm = () => {
    if (
      selectedMonth &&
      selectedDay &&
      selectedTime &&
      selectedEventId &&
      customerService &&
      businessId
    ) {
      const [startTime, endTime] = selectedTime.split(" - ");
      dispatch(
        bookSlot({
          businessId,
          eventId: selectedEventId,
          customerName,
          customerPhone,
          serviceId: customerService.id,
          startTime,
          endTime,
        }),
      );
    }
  };

  const getAvailableSlots = () => {
    if (businessId && customerService) {
      dispatch(fetchSlots({ businessId, duration: customerService.duration }));
    }
  };

  useEffect(() => {
    getAvailableSlots();
  }, [businessId]);

  useEffect(() => {
    if (bookSlotSucceeded) {
      setSelectedMonth(null);
      setSelectedDay(null);
      setSelectedDayLabel(null);
      setSelectedTime(null);
      setSelectedEventId(null);
      incrementStep();
      navigate(`/${businessId}/success`);
    }
  }, [bookSlotSucceeded]);

  useEffect(() => {
    if (selectedMonth !== null && daySectionRef.current) {
      daySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDay !== null && timeSectionRef.current) {
      timeSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (selectedTime !== null && confirmationSectionRef.current) {
      confirmationSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedTime]);

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={3} />
      {fetchingSlots ? (
        <KLPleaseWait text="בודקים מתי יש תורים פנויים" />
      ) : (
        <>
          {!availableSlots.length ? (
            <>
              <Title>אין דרך קלה להגיד את זה אבל...</Title>
              <Subtitle>לא הצלחנו למצוא תור פנוי :(</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
              <KLButton
                title={"אולי בכל זאת?"}
                onClick={getAvailableSlots}
                disabled={fetchingSlots}
              />
            </>
          ) : (
            <>
              <Title>מתי מתאים לך להגיע?</Title>
              <div>
                <Subtitle>באיזה חודש?</Subtitle>
                <MonthSelection>
                  {months.map((month, index) => (
                    <Chip
                      sx={chipSx(selectedMonth === month)}
                      key={index}
                      label={month}
                      color={selectedMonth === month ? "primary" : "default"}
                      onClick={() => handleMonthSelect(month)}
                      style={{ margin: "0.25rem" }}
                    />
                  ))}
                </MonthSelection>
              </div>

              {selectedMonth && (
                <div ref={daySectionRef}>
                  <Subtitle>באיזה יום ב{selectedMonth}?</Subtitle>
                  <DaySelection>
                    {days.map((dayObj) => (
                      <ChipWrapper key={dayObj.date}>
                        <Chip
                          sx={chipSx(selectedDay === dayObj.date)}
                          label={dayObj.label}
                          color={
                            selectedDay === dayObj.date ? "primary" : "default"
                          }
                          onClick={() => handleDaySelect(dayObj)}
                          style={{ width: "100%" }}
                        />
                      </ChipWrapper>
                    ))}
                  </DaySelection>
                </div>
              )}

              {selectedDay && selectedDayLabel && (
                <div ref={timeSectionRef}>
                  <Subtitle>באיזו שעה ב{selectedDayLabel}?</Subtitle>
                  <TimeSlots>
                    {times.map(
                      ({ startTime, endTime, displayTime, eventId }) => (
                        <ChipWrapper key={startTime}>
                          <Chip
                            sx={chipSx(
                              selectedTime === `${startTime} - ${endTime}`,
                            )}
                            label={displayTime}
                            color={
                              selectedTime === `${startTime} - ${endTime}`
                                ? "primary"
                                : "default"
                            }
                            onClick={() =>
                              handleTimeSelect(
                                startTime,
                                endTime,
                                displayTime,
                                eventId,
                              )
                            }
                            style={{ width: "100%" }}
                          />
                        </ChipWrapper>
                      ),
                    )}
                  </TimeSlots>
                </div>
              )}

              {selectedTime && business && customerService && (
                <ConfirmationSection ref={confirmationSectionRef}>
                  <SlotSummary>
                    <strong>מצוין!</strong> אז אנחנו קובעים לכם{" "}
                    <strong>{customerService.description}</strong> ב
                    <strong>{business.businessName}</strong> ב
                    <strong>{selectedDayLabel}</strong> בשעה{" "}
                    <strong>{selectedDisplayTime}</strong>
                  </SlotSummary>
                  <KLButton
                    title={"הולכים על זה"}
                    onClick={handleConfirm}
                    disabled={!selectedTime || bookingSlot}
                    loading={bookingSlot}
                  />
                </ConfirmationSection>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CustomerSlotsScreen;
