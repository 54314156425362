import styled from "styled-components";
import colors from "../../../constants/colors";
import { FieldWrapper } from "../../styles";

export const ServicesFieldWrapper = styled(FieldWrapper)({
  marginBottom: "0.5rem",
});

export const CheckboxWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: "0.5rem",
  label: {
    fontSize: "14px",
    color: colors.BLACK,
  },
});

export const ServicesDisclaimer = styled("div")({
  width: "100%",
  textAlign: "right",
  fontSize: "12px",
});

export const DeleteButton = styled("button")({
  background: "none",
  border: "none",
  color: colors.RED,
  cursor: "pointer",
  fontSize: "1.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    padding: "0.25rem",
    textAlign: "right",
    fontFamily: "inherit",
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    zIndex: 100,
  }),
};
