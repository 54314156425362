import React from "react";
import { Link } from "react-router-dom";
import { Divider, FooterContainer } from "./styles";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Link to="/privacy-policy">מדיניות פרטיות</Link>
      <Divider>|</Divider>
      <Link to="/terms-of-service">תנאי שירות</Link>
    </FooterContainer>
  );
};

export default Footer;
