import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import MainScreen from "./screens/MainScreen";
import CustomerInfoScreen from "./screens/CustomerInfoScreen";
import CustomerSuccessScreen from "./screens/SuccessScreen";
import CustomerSlotsScreen from "./screens/CustomerSlotsScreen";
import CustomerServicesScreen from "./screens/CustomerServicesScreen";
import AuthorizeScreen from "./screens/Onboarding/AuthorizeScreen";
import OwnerInfoScreen from "./screens/Onboarding/OwnerInfoScreen";
import SlotsSetupScreen from "./screens/Onboarding/SlotsSetupScreen";
import OnboardingSuccessScreen from "./screens/Onboarding/OnboardingSuccessScreen";
import CustomerMainScreen from "./screens/CustomerMainScreen";
import ServicesSetupScreen from "./screens/Onboarding/ServicesSetupScreen";
import PrivacyPolicyScreen from "./screens/Legal/PrivacyPolicyScreen";
import LayoutWrapper from "./Layout/LayoutWrapper";
import SequentialRoute from "./routes/SequentialRoute";
import NotAuthorizedScreen from "./screens/NotAuthorized";
import { StepProvider } from "./context/StepContext";
import TermsOfServiceScreen from "./screens/Legal/TermsOfServiceScreen";
import CustomerExistingSlotsScreen from "./screens/CustomerExistingSlotsScreen";
import ShortLinkRedirectScreen from "./screens/ShortLinkRedirectionScreen";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <StepProvider>
        <Router>
          <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route
              path="/terms-of-service"
              element={<TermsOfServiceScreen />}
            />
            <Route path="/not-authorized" element={<NotAuthorizedScreen />} />
            <Route path="/m/:shortCode" element={<ShortLinkRedirectScreen />} />
            <Route element={<LayoutWrapper />}>
              <Route path="/" element={<MainScreen />} />
              <Route path="/:businessId" element={<CustomerMainScreen />} />
              <Route
                path="/:businessId/customer-info"
                element={
                  <SequentialRoute
                    requiredStep={1}
                    element={<CustomerInfoScreen />}
                  />
                }
              />
              <Route
                path="/:businessId/services"
                element={
                  <SequentialRoute
                    requiredStep={2}
                    element={<CustomerServicesScreen />}
                  />
                }
              />
              <Route
                path="/:businessId/existingSlots"
                element={
                  <SequentialRoute
                    requiredStep={2}
                    element={<CustomerExistingSlotsScreen />}
                  />
                }
              />
              <Route
                path="/:businessId/slots"
                element={
                  <SequentialRoute
                    requiredStep={3}
                    element={<CustomerSlotsScreen />}
                  />
                }
              />
              <Route
                path="/:businessId/success"
                element={
                  <SequentialRoute
                    requiredStep={4}
                    element={<CustomerSuccessScreen />}
                  />
                }
              />

              {/* Onboarding flow with strict sequential access */}
              <Route
                path="/onboarding/owner-info"
                element={
                  <SequentialRoute
                    requiredStep={1}
                    element={<OwnerInfoScreen />}
                  />
                }
              />
              <Route
                path="/onboarding/services"
                element={
                  <SequentialRoute
                    requiredStep={2}
                    element={<ServicesSetupScreen />}
                  />
                }
              />
              <Route
                path="/onboarding/authorize"
                element={
                  <SequentialRoute
                    requiredStep={3}
                    element={<AuthorizeScreen />}
                  />
                }
              />
              <Route
                path="/onboarding/slots"
                element={
                  <SequentialRoute
                    requiredStep={4}
                    element={<SlotsSetupScreen />}
                  />
                }
              />
              <Route
                path="/onboarding/success"
                element={
                  <SequentialRoute
                    requiredStep={5}
                    element={<OnboardingSuccessScreen />}
                  />
                }
              />
            </Route>
          </Routes>
        </Router>
      </StepProvider>
    </Provider>
  );
};

export default App;
