import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  setCustomerName,
  setCustomerPhone,
} from "../../store/duckers/customers/slice";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  InfoText,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import { checkCustomerSlots } from "../../store/duckers/customers/thunk";
import KLPleaseWait from "../../components/KLPleaseWait";

const CustomerInfoScreen: React.FC = () => {
  const navigate = useNavigate();
  const { incrementStep } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const {
    fetchingCustomerSlots,
    customerSlots,
    customerName: storedName,
    customerPhone: storedPhone,
  } = useSelector((state: RootState) => state.customers);
  const [name, setName] = useState<string>(storedName || "");
  const [phone, setPhone] = useState<string>(storedPhone || "");
  const [confirmDetailsClicked, setConfirmDetailsClicked] =
    useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const { businessId } = useParams<{ businessId: string }>();

  const isFormValid = useMemo(() => {
    return name.trim() !== "" && isPhoneValid;
  }, [name, isPhoneValid]);

  const handleConfirm = () => {
    if (isFormValid && businessId) {
      dispatch(setCustomerName(name));
      dispatch(setCustomerPhone(phone));
      dispatch(checkCustomerSlots({ businessId, phone }));
      setConfirmDetailsClicked(true);
    }
  };

  useEffect(() => {
    if (!confirmDetailsClicked || fetchingCustomerSlots) {
      return;
    } else if (customerSlots.length > 0) {
      incrementStep();
      navigate(`/${businessId}/existingSlots`);
    } else {
      incrementStep();
      navigate(`/${businessId}/services`);
    }
  }, [customerSlots]);

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={1} />
      {fetchingCustomerSlots ? (
        <KLPleaseWait text="בודקים תורים קיימים" />
      ) : (
        <>
          <Title>עם מי יש לנו הכבוד?</Title>
          <InfoText>
            נשמח לקבל מכם שם וטלפון כדי לקבוע לכם תורים חדשים ולבדוק אם יש כבר
            קיימים.
          </InfoText>
          <FormWrapper onSubmit={handleConfirm}>
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם ומשפחה"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="tel"
                placeholder="טלפון"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onValidationChange={setIsPhoneValid}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLButton title={"ממשיכים"} disabled={!isFormValid} />
            </FieldWrapper>
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default CustomerInfoScreen;
