import React from "react";
import { Container } from "./styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { InfoText, Subtitle, Title } from "../styles";

const CustomerSuccessScreen: React.FC = () => {
  return (
    <Container>
      <KLCustomerBookingProgress currentStep={4} />
      <Title>איזה כיף, קבענו לי :)</Title>
      <Subtitle>מחכים ומצפים לכם ביום ובשעה שקבעתם!</Subtitle>
      <InfoText>שלחנו לכם הודעת סמס עם כל הפרטים, ליתר ביטחון.</InfoText>
    </Container>
  );
};

export default CustomerSuccessScreen;
