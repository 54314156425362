import React, { useEffect } from "react";
import { Outlet, useLocation, matchPath } from "react-router-dom";
import KLFooter from "../../components/KLFooter";
import KLHeader from "../../components/KLHeader";
import { Wrapper, Container } from "./styles";

const LayoutWrapper: React.FC = () => {
  const location = useLocation();

  const hideHeader = matchPath("/:businessId", location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Wrapper>
      <Container>
        {!hideHeader && <KLHeader />}
        <Outlet />
        <KLFooter />
      </Container>
    </Wrapper>
  );
};

export default LayoutWrapper;
