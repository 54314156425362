import styled from "styled-components";
import colors from "../../constants/colors";
import { ContentWrapper } from "../styles";

export const SlotItem = styled("div")({
  backgroundColor: colors.PURPLE_LIGHTEST,
  borderRadius: "10px",
  width: "100%",
  marginBottom: "1rem",
  border: `0.5px solid ${colors.PURPLE}`,
});

export const SlotContentWrapper = styled(ContentWrapper)({
  padding: "0.5rem",
});

export const SlotInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
});

export const SlotWhen = styled("div")({
  fontSize: "14px",
  color: colors.BLACK,
});

export const ServiceDescription = styled("div")({
  fontSize: "18px",
  fontWeight: "bold",
  color: colors.BLACK,
  marginBottom: "0.2rem",
});

export const ServicePriceDuration = styled("span")({
  fontSize: "14px",
  color: colors.BLACK,
  marginBottom: "0.25rem",
});

export const CancellationExplanation = styled("div")({
  color: colors.RED,
  fontSize: "12px",
  fontWeight: "bold",
  lineHeight: "16px",
  marginBlock: "0.5rem",
  paddingInline: "0.25rem",
});
