import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { ImageWrapper, BusinessName, OwnerName } from "./styles";
import KLButton from "../../components/KLButton";
import { fetchBusiness } from "../../store/duckers/customers/thunk";
import defaultImage from "../../assets/images/default.webp";
import mainImageMap, { category } from "../../constants/customers/mainImageMap";
import { Container, InfoText, Subtitle, Title } from "../styles";
import KLPleaseWait from "../../components/KLPleaseWait";

const CustomerMainScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId } = useParams<{ businessId: string }>();

  const { business, fetchingBusiness, error } = useSelector(
    (state: RootState) => state.customers,
  );

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleBookNow = () => {
    navigate(`/${businessId}/customer-info`);
  };

  useEffect(() => {
    if (!businessId) return;
    dispatch(fetchBusiness(businessId));
  }, [dispatch, businessId]);

  useEffect(() => {
    if (business?.category) {
      const fileName = mainImageMap[business.category as category];
      if (fileName) {
        import(`../../assets/images/${fileName}.webp`)
          .then((image) => {
            setImageSrc(image.default);
          })
          .catch((error) => {
            console.error("Error loading image:", error);
            setImageSrc(defaultImage);
          });
      } else {
        setImageSrc(defaultImage);
      }
    }
  }, [business?.category]);

  return (
    <Container>
      {fetchingBusiness ? (
        <KLPleaseWait text="בודקים עם מי יש לנו עסק" />
      ) : error ? (
        <>
          <Title>אופס!</Title>
          <InfoText>זה קצת עסק ביש. אנא נסו שוב.</InfoText>
        </>
      ) : business ? (
        <>
          {imageSrc && (
            <ImageWrapper>
              <img src={imageSrc} alt={business.category || "default"} />
            </ImageWrapper>
          )}
          <BusinessName>{business.businessName}</BusinessName>
          <OwnerName>{business.ownerName}</OwnerName>
          <Subtitle>קבעו תור עתידי באישור מיידי!</Subtitle>
          <InfoText>
            עשו זאת בקלות - בחרו את סוג השירות שאתם רוצים, ואת היום והשעה
            שמתאימים לכם, וזהו!
          </InfoText>
          <KLButton title={"בואו נתחיל"} onClick={handleBookNow} />
        </>
      ) : null}
    </Container>
  );
};

export default CustomerMainScreen;
