import styled from "styled-components";
import { Subtitle, Title } from "../styles";

export const BusinessName = styled(Title)({
  fontSize: "36px",
  margin: 0,
});

export const OwnerName = styled(Subtitle)({
  fontSize: "20px",
  margin: "0 0 2rem 0",
});

export const ImageWrapper = styled("div")({
  width: "100%",
  marginBottom: "1rem",
  img: {
    width: "100%",
    borderRadius: "15px",
  },
});
