import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TokenPayload, VerifyTokenResponse } from "../../../constants/types";
import { verifyTokenThunk } from "./thunk";

interface AuthState {
  isTokenVerified: boolean;
  userData: TokenPayload | null;
  verifyingToken: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isTokenVerified: false,
  userData: null,
  verifyingToken: false,
  error: null,
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyTokenThunk.pending, (state) => {
        state.verifyingToken = true;
        state.error = null;
      })
      .addCase(
        verifyTokenThunk.fulfilled,
        (state, action: PayloadAction<VerifyTokenResponse>) => {
          state.verifyingToken = false;
          if (action.payload.valid && action.payload.payload) {
            state.isTokenVerified = true;
            state.userData = action.payload.payload;
          } else {
            state.isTokenVerified = false;
            state.userData = null;
            state.error = action.payload.message || "Token is invalid";
          }
        },
      )
      .addCase(verifyTokenThunk.rejected, (state, action) => {
        state.verifyingToken = false;
        state.isTokenVerified = false;
        state.userData = null;
        state.error = action.payload || "Failed to verify token";
      });
  },
});

export default tokensSlice.reducer;
