import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useStep } from "../../context/StepContext";
import { useDispatch, useSelector } from "react-redux";
import { verifyTokenThunk } from "../../store/duckers/tokens/thunk";
import { AppDispatch, RootState } from "../../store";
import KLPleaseWait from "../../components/KLPleaseWait";

interface SequentialRouteProps {
  requiredStep: number;
  element: React.ReactElement;
}

const SequentialRoute: React.FC<SequentialRouteProps> = ({
  requiredStep,
  element,
}) => {
  const { currentStep, isRedirecting, maxStepReached, setStep } = useStep();
  const { search } = useLocation();
  const redirectToken = new URLSearchParams(search).get("redirect_token");
  const urlStep = new URLSearchParams(search).get("step");
  const stepToValidate = urlStep ? parseInt(urlStep, 10) : currentStep;

  const dispatch = useDispatch<AppDispatch>();

  const { isTokenVerified, verifyingToken } = useSelector(
    (state: RootState) => state.tokens,
  );

  const [hasDispatched, setHasDispatched] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("redirect_token");

    if (token && !hasDispatched) {
      dispatch(verifyTokenThunk(token));
      setHasDispatched(true);

      searchParams.delete("redirect_token");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, [search, dispatch, hasDispatched]);

  useEffect(() => {
    if (isTokenVerified) {
      setStep(requiredStep);
    }
  }, [isTokenVerified, requiredStep, setStep]);

  if (isRedirecting) {
    return element;
  }

  if (redirectToken) {
    if (!hasDispatched || verifyingToken) {
      return <KLPleaseWait text="עוד רגע זה קורה" />;
    }

    if (isTokenVerified) {
      return element;
    }

    if (hasDispatched && !isTokenVerified) {
      return <Navigate to="/not-authorized" replace />;
    }

    return <KLPleaseWait text="Loading..." />;
  }

  if (stepToValidate <= maxStepReached && stepToValidate >= requiredStep) {
    return element;
  }

  if (stepToValidate < requiredStep) {
    return <Navigate to="/not-authorized" replace />;
  }

  return element;
};

export default SequentialRoute;
