import React from "react";
import {
  ProgressContainer,
  ProgressFill,
  Divider,
  LabelContainer,
  Label,
  Container,
} from "./styles";

type KLProgressBarProps = {
  currentStep: number;
  totalSteps: number;
  labels: string[];
};

const KLProgressBar: React.FC<KLProgressBarProps> = ({
  currentStep,
  totalSteps,
  labels,
}) => {
  const progress = (currentStep / totalSteps) * 100;
  const sectionWidth = 100 / totalSteps;

  return (
    <Container>
      <LabelContainer>
        {labels.map((label, index) => (
          <Label key={index}>{label}</Label>
        ))}
      </LabelContainer>
      <ProgressContainer>
        <ProgressFill $progress={progress} />
        {Array.from({ length: totalSteps - 1 }).map((_, index) => (
          <Divider key={index} $position={(index + 1) * sectionWidth} />
        ))}
      </ProgressContainer>
    </Container>
  );
};

export default KLProgressBar;
