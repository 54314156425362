import styled from "styled-components";
import colors from "../../constants/colors";

export const Button = styled("button")<{
  $secondary?: boolean;
  $warning?: boolean;
  $noWidth?: boolean;
  $noMarginTop?: boolean;
  disabled: boolean;
}>(({ $secondary, $warning, $noWidth, $noMarginTop, disabled }) => ({
  padding: "0.75rem",
  fontSize: $secondary || $warning ? "16px" : "18px",
  backgroundColor: disabled
    ? colors.GREY_3
    : $secondary
      ? colors.WHITE
      : $warning
        ? colors.RED
        : colors.PURPLE,
  color: disabled ? colors.GREY_6 : $secondary ? colors.PURPLE : colors.WHITE,
  border: $secondary ? `1px solid ${colors.PURPLE}` : "none",
  borderRadius: "8px",
  cursor: disabled ? "not-allowed" : "pointer",
  opacity: disabled ? 0.6 : 1,
  transition: "background-color 0.3s, opacity 0.3s",
  width: $noWidth ? "none" : "100%",
  marginTop: $noMarginTop ? 0 : "2rem",
}));
