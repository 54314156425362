import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceWithId } from "../../../constants/types";
import { fetchServices } from "./thunk";

interface ServicesState {
  services: ServiceWithId[];
  fetchingServices: boolean;
  error: string | null;
}

const initialState: ServicesState = {
  services: [],
  fetchingServices: false,
  error: null,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.fetchingServices = true;
        state.error = null;
      })
      .addCase(
        fetchServices.fulfilled,
        (state, action: PayloadAction<ServiceWithId[]>) => {
          state.services = action.payload;
          state.fetchingServices = false;
        },
      )
      .addCase(fetchServices.rejected, (state, action) => {
        state.error = action.payload ?? "Failed to fetch services";
        state.fetchingServices = false;
      });
  },
});

export default servicesSlice.reducer;
