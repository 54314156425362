import axios from "axios";
import { Business } from "../constants/types";

class BusinessService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async fetchBusiness(businessId: string): Promise<Business> {
    try {
      const response = await axios.get<Business>(
        `${this.baseApiUrl}/api/${businessId}`,
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );
      const business = response.data;
      if (!business || typeof business !== "object") {
        throw new Error("Invalid business response structure");
      }
      return business;
    } catch (e) {
      throw new Error("Failed to fetch business");
    }
  }
}

export const businessService = new BusinessService();
