import React from "react";
import { useNavigate } from "react-router-dom";
import KLHeader from "../../components/KLHeader";
import KLButton from "../../components/KLButton";
import { ButtonWrapper, Container, InfoText, Subtitle, Title } from "../styles";

const NotAuthorizedScreen: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <KLHeader />
      <Title>שלום! אפשר לעזור?</Title>
      <Subtitle>לא בטוח שלכאן התכוונתם להגיע...</Subtitle>
      <InfoText>אולי כדאי שניקח צעד אחורה?</InfoText>
      <ButtonWrapper>
        <KLButton title="חזרה לדף הבית" onClick={handleGoHome} />
      </ButtonWrapper>
    </Container>
  );
};

export default NotAuthorizedScreen;
