import colors from "../../../constants/colors";

export const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    border: `1px solid ${colors.GREY_3}`,
    borderRadius: "8px",
    padding: "0.25rem",
    fontSize: "16px",
    fontFamily: "inherit",
    color: colors.BLACK,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.BLACK,
    fontSize: "16px",
    fontFamily: "inherit",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colors.BLACK,
    fontSize: "16px",
    fontFamily: "inherit",
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    zIndex: 10,
    fontSize: "16px",
    fontFamily: "inherit",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "16px",
    fontFamily: "inherit",
    backgroundColor: state.isSelected ? colors.GREY_2 : colors.WHITE,
    color: state.isSelected ? colors.BLACK : colors.BLACK,
  }),
};
