import styled from "styled-components";
import colors from "../../../constants/colors";

export const BusinessIconWrapper = styled("div")({
  width: "100px",
  ".MuiSvgIcon-root": {
    fontSize: "3.5rem",
    color: colors.BLACK,
  },
});
