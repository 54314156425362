import axios from "axios";

class CategoriesService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async fetchCategories(): Promise<string[]> {
    try {
      const response = await axios.get<string[]>(
        `${this.baseApiUrl}/api/categories`,
        {
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );

      const categories = response.data;

      if (!Array.isArray(categories)) {
        throw new Error("Invalid categories response structure");
      }

      return categories;
    } catch (e) {
      throw new Error("Failed to fetch categories");
    }
  }
}

export const categoriesService = new CategoriesService();
