import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchOriginalUrl } from "../../store/duckers/shortLinks/thunk";
import KLPleaseWait from "../../components/KLPleaseWait";

const ShortLinkRedirectScreen: React.FC = () => {
  const { shortCode } = useParams<{ shortCode: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { originalUrl, fetchingOriginalUrl, error } = useSelector(
    (state: RootState) => state.shortLinks,
  );

  useEffect(() => {
    if (shortCode) {
      dispatch(fetchOriginalUrl(shortCode));
    }
  }, [dispatch, shortCode]);

  useEffect(() => {
    if (originalUrl) {
      window.location.href = originalUrl;
    } else if (error) {
      navigate("/not-authorized");
    }
  }, [originalUrl, error, navigate]);

  if (fetchingOriginalUrl) {
    return <KLPleaseWait text="עוד רגע זה קורה" />;
  }

  return <KLPleaseWait text="עוד רגע זה קורה" />;
};

export default ShortLinkRedirectScreen;
