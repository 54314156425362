import React from "react";
import { Container, Title } from "../../styles";
import { ContentWrapper, Content } from "../styles";

const PrivacyPolicyScreen: React.FC = () => {
  return (
    <Container>
      <Title>מדיניות פרטיות</Title>
      <ContentWrapper>
        <Content>
          <strong>תאריך כניסה לתוקף:</strong> 24/09/2024
          <br />
          <br />
          ב-<strong>קבענו לי</strong>, אנו מחויבים להגן על פרטיות המשתמשים שלנו.
          מדיניות פרטיות זו מסבירה כיצד אנו אוספים, משתמשים, מאחסנים ומגנים על
          המידע האישי שלך בעת השימוש בשירותים שלנו.
          <br />
          <br />
          <strong>1. המידע שאנו אוספים</strong>
          <br />
          אנו אוספים את סוגי המידע הבאים כדי לספק לך את השירותים שלנו:
          <br />
          <br />
          <strong>א. מידע אישי</strong>
          <br />
          מידע מחשבון Google: כאשר אתה מאשר לאפליקציה שלנו גישה ליומן Google
          שלך, אנו אוספים את כתובת האימייל שלך ואת נתוני היומן (כגון משבצות זמן,
          אירועים) הנדרשים לצורך קביעת תורים.
          <br />
          <br />
          <strong>ב. נתוני שימוש</strong>
          <br />
          אנו אוספים נתונים על האופן שבו אתה משתמש באפליקציה שלנו, כגון מספר
          התורים שנקבעו, מידע על מכשירים, ודפוסי שימוש כלליים לצורך שיפור חווית
          המשתמש.
          <br />
          <br />
          <strong>2. כיצד אנו משתמשים במידע שלך</strong>
          <br />
          אנו משתמשים במידע שלך כדי:
          <br />
          - לספק ולנהל את שירותי קביעת התורים.
          <br />
          - להציג משבצות זמן זמינות מיומן Google שלך לצורך קביעת תורים.
          <br />
          - לעדכן את יומן Google שלך עם תורים מאושרים.
          <br />
          - לשלוח הודעות הקשורות לתורים שלך.
          <br />
          - לנתח ביצועי האפליקציה ולשפר את השירותים שלנו.
          <br />
          <br />
          <strong>3. שיתוף המידע שלך</strong>
          <br />
          אנו <strong>לא</strong> מוכרים או משכירים את המידע האישי שלך לצדדים
          שלישיים. אנו עשויים לשתף את המידע שלך עם:
          <br />- <strong>ספקי שירותים</strong>: צדדים שלישיים המספקים שירותי
          אירוח, תחזוקה או ניתוח אפליקציה. גישה לצדדים אלו ניתנת רק לצורך ביצוע
          משימות בשמנו, והם מחויבים בהסכמי סודיות.
          <br />- <strong>דרישות חוקיות</strong>: במקרה של דרישה חוקית, אנו
          עשויים לחשוף את המידע שלך בהתאם לבקשות תקפות מצד רשויות ציבוריות.
          <br />
          <br />
          <strong>4. אבטחת מידע</strong>
          <br />
          אנו נוקטים צעדי אבטחה מתקדמים כדי להגן על המידע שלך מפני גישה, שינוי,
          חשיפה או הרס בלתי מורשה. צעדים אלו כוללים הצפנה של נתונים רגישים
          וביקורות אבטחה סדירות.
          <br />
          <br />
          <strong>5. הזכויות שלך</strong>
          <br />
          יש לך את הזכויות הבאות לגבי המידע האישי שלך:
          <br />- <strong>גישה</strong>: באפשרותך לבקש גישה למידע האישי שיש לנו
          אודותיך.
          <br />- <strong>תיקון</strong>: באפשרותך לבקש תיקון של כל מידע שגוי או
          לא שלם.
          <br />- <strong>מחיקה</strong>: באפשרותך לבקש מחיקה של המידע שלך
          ממערכותינו, בכפוף להתחייבויות חוקיות.
          <br />
          <br />
          <strong>6. שמירת נתונים</strong>
          <br />
          אנו נשמור את המידע האישי שלך רק כל עוד הוא נחוץ לצורך מתן השירותים
          שלנו ולמטרות עסקיות לגיטימיות. אם תבקש מחיקת המידע שלך, אנו נעמוד
          בבקשה בהקדם האפשרי.
          <br />
          <br />
          <strong>7. קישורים לצדדים שלישיים</strong>
          <br />
          האפליקציה שלנו עשויה לכלול קישורים לאתרים של צדדים שלישיים, כמו
          Google, לצורך שילוב יומנים. מדיניות פרטיות זו אינה חלה על שירותים של
          צדדים שלישיים, ואנו ממליצים לבדוק את מדיניות הפרטיות שלהם בנפרד.
          <br />
          <br />
          <strong>8. שינויים במדיניות פרטיות זו</strong>
          <br />
          אנו עשויים לעדכן את מדיניות פרטיות זו מעת לעת. כל שינוי יתפרסם בעמוד
          זה עם תאריך עדכון חדש.
          <br />
          <br />
          <strong>9. יצירת קשר</strong>
          <br />
          אם יש לך שאלות בנוגע למדיניות פרטיות זו או לתהליכי ניהול הנתונים שלנו,
          ניתן ליצור איתנו קשר ב:
          <br />
          <br />
          <strong>קבענו לי</strong>
          <br />
          אימייל: info@kavanu.li
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default PrivacyPolicyScreen;
