import React from "react";
import { AppName, HeaderContainer, Logo, LogoLink } from "./styles";
import logo from "../../assets/logo.png";

const KLHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <LogoLink to="/">
        <Logo src={logo} alt="Company Logo" />
        <AppName>kavanu.li</AppName>
      </LogoLink>
    </HeaderContainer>
  );
};

export default KLHeader;
