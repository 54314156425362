export const durationOptions = [
  { value: 30, label: "30 דקות" },
  { value: 45, label: "45 דקות" },
  { value: 60, label: "שעה" },
  { value: 90, label: "שעה וחצי" },
  { value: 120, label: "שעתיים" },
];

export const getDurationLabel = (value: number) => {
  const option = durationOptions.find((option) => option.value === value);
  return option ? option.label : `${value} דקות`;
};
