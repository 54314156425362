import React from "react";
import { IconWrapper, CogIconWrapper } from "./styles";
import EventIcon from "@mui/icons-material/Event";
import SettingsIcon from "@mui/icons-material/Settings";

const KLCalendarGearIcon: React.FC = () => {
  return (
    <IconWrapper>
      <EventIcon />
      <CogIconWrapper>
        <SettingsIcon />
      </CogIconWrapper>
    </IconWrapper>
  );
};

export default KLCalendarGearIcon;
