import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceWithId } from "../../../constants/types";
import { servicesService } from "../../../services/servicesService";

export const fetchServices = createAsyncThunk<
  ServiceWithId[],
  string,
  { rejectValue: string }
>("services/fetchServices", async (businessId, { rejectWithValue }) => {
  try {
    return await servicesService.fetchServices(businessId);
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});
