import React from "react";
import { Container, Title } from "../../styles";
import { Content, ContentWrapper } from "../styles";

const TermsOfServiceScreen: React.FC = () => {
  return (
    <Container>
      <Title>תנאי שירות</Title>
      <ContentWrapper>
        <Content>
          <strong>תאריך כניסה לתוקף:</strong> 24/09/2024
          <br />
          <br />
          ברוכים הבאים ל-<strong>kavanu.li</strong>. אנא קראו בעיון את תנאי
          השימוש הללו לפני השימוש באתר ובשירותים המוצעים בו. השימוש באתר מהווה
          הסכמה מפורשת שלכם לתנאים הללו.
          <br />
          <br />
          <strong>1. הסכמה לתנאי השימוש</strong>
          <br />
          בכניסה לאתר או בשימוש בכל אחד מהשירותים המוצעים על ידי kavanu.li
          (להלן: "המפתח"), אתם מאשרים שקראתם והבנתם את תנאי השימוש, וכי אתם
          מסכימים להיות מחויבים להם.
          <br />
          <br />
          <strong>2. שינויים בתנאים</strong>
          <br />
          המפתח שומר לעצמו את הזכות לעדכן או לשנות תנאים אלה בכל עת, לפי שיקול
          דעתו הבלעדי, תוך מתן הודעה מתאימה באמצעות האתר או בדוא"ל. באחריות
          המשתמש לעיין בתנאים באופן תקופתי. המשך השימוש באתר לאחר שינויים אלו
          ייחשב כהסכמה לשינויים.
          <br />
          <br />
          <strong>3. היקף השירותים</strong>
          <br />
          kavanu.li מספקת שירותי ניהול תורים דיגיטליים, סנכרון יומנים ושירותים
          נוספים הנוגעים לניהול הזמנות ותורים לעסקים. השירותים ניתנים "כפי שהם"
          והמפתח אינו מתחייב לזמינות מלאה או לשימוש רציף ונטול תקלות.
          <br />
          <br />
          <strong>4. הרשמה ושימוש באתר</strong>
          <br />
          כדי להשתמש בחלק מהשירותים, ייתכן שתידרש הרשמה, כולל מתן פרטים אישיים
          ומסחריים מדויקים ועדכניים. אתם מתחייבים כי כל המידע שיינתן הינו נכון
          ומדויק, וכי אינכם תמסרו פרטים כוזבים או לא מלאים.
          <br />
          <br />
          <strong>5. זכויות קניין רוחני</strong>
          <br />
          כל התכנים באתר, לרבות עיצובים, טקסטים, קודים גרפיים, אלגוריתמים, וכל
          שאר החומרים הינם בבעלות המפתח ואין לעשות בהם שימוש ללא אישור מפורש
          בכתב.
          <br />
          <br />
          <strong>6. פרטיות ואבטחת מידע</strong>
          <br />
          המפתח מתחייב לשמור על פרטיות ואבטחת המידע של המשתמשים בהתאם למדיניות
          הפרטיות המופיעה באתר.
          <br />
          <br />
          <strong>7. אחריות והגבלת אחריות</strong>
          <br />
          המפתח לא יישא באחריות לכל נזק ישיר, עקיף, תוצאתי או מיוחד שייגרם
          כתוצאה משימושכם באתר או בשירותים, לרבות אובדן הכנסות, הפסדים, עיכובים
          או הפסקות בפעילות עסקית. השירותים ניתנים "כפי שהם", והמפתח אינו מעניק
          כל אחריות לגבי התאמתם לצרכים או לתוצאות שיתקבלו משימוש בהם.
          <br />
          <br />
          <strong>8. סיום השירות</strong>
          <br />
          המפתח שומר לעצמו את הזכות לסיים או להשעות את השימוש שלכם בשירותים
          במקרה של הפרת התנאים, או מכל סיבה אחרת, על פי שיקול דעתו הבלעדי.
          <br />
          <br />
          <strong>9. שיפוי</strong>
          <br />
          המשתמש מסכים לשפות את המפתח בגין כל נזק, הפסד, תביעה או הוצאה שייגרמו
          בעקבות הפרה של תנאי השימוש או של כל חוק חל.
          <br />
          <br />
          <strong>10. דיני שיפוט</strong>
          <br />
          הסכם זה והתנאים שבו יהיו כפופים לחוקי מדינת ישראל, וכל סכסוך הנוגע
          אליו יידון בבית המשפט המוסמך בחיפה.
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default TermsOfServiceScreen;
