import { createAsyncThunk } from "@reduxjs/toolkit";
import { Business, CustomerSlot } from "../../../constants/types";
import { businessService } from "../../../services/businessService";
import { customerService } from "../../../services/customerService";

export const fetchBusiness = createAsyncThunk<
  Business,
  string,
  { rejectValue: string }
>("business/fetchBusiness", async (businessId, { rejectWithValue }) => {
  try {
    return await businessService.fetchBusiness(businessId);
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});

export const checkCustomerSlots = createAsyncThunk<
  { customerSlots: CustomerSlot[] },
  { businessId: string; phone: string },
  { rejectValue: string }
>(
  "customers/checkCustomerSlots",
  async ({ businessId, phone }, { rejectWithValue }) => {
    try {
      const response = await customerService.checkCustomerSlots(
        businessId,
        phone,
      );
      return { customerSlots: response };
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
