import styled from "styled-components";
import colors from "../../../constants/colors";

export const SlotWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "1.5rem",
  width: "100%",
});

export const DeleteButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

export const DeleteButton = styled("button")({
  background: "none",
  border: "none",
  color: colors.RED,
  cursor: "pointer",
  fontSize: "1.5rem",
  marginRight: "1rem",
  padding: "0",
  display: "flex",
  alignItems: "center",
  height: "40px",
});

export const SlotContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

export const TimeRow = styled("div")({
  display: "flex",
  marginTop: "0.75rem",
  gap: "0.75rem",
});

export const DropdownWrapper = styled("div")({
  flex: "1",
  marginRight: "0.75rem",
  "&:last-child": {
    marginRight: "0",
  },
});

export const reactSelectStyles = () => ({
  control: (provided: any) => ({
    ...provided,
    fontSize: "16px",
    borderRadius: "4px",
    border: `1px solid ${colors.GREY_4}`,
    boxShadow: "none",
    "&:hover": {
      borderColor: colors.PURPLE,
    },
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: "16px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: "16px",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "4px",
    svg: {
      width: "12px",
      height: "12px",
    },
  }),
});
