import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoriesService } from "../../../services/categoriesService";

export const fetchCategories = createAsyncThunk<
  string[],
  void,
  { rejectValue: string }
>("categories/fetchCategories", async (_, { rejectWithValue }) => {
  try {
    return await categoriesService.fetchCategories();
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});
