import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { PleaseWaitContainer, Subtitle, Title } from "../../screens/styles";

interface KLPleaseWaitProps {
  text: string;
}

const KLPleaseWait: React.FC<KLPleaseWaitProps> = ({ text }) => {
  return (
    <PleaseWaitContainer>
      <Title>כמה רגעים...</Title>
      <Subtitle>{text}</Subtitle>
      <CircularProgress size={54} />
    </PleaseWaitContainer>
  );
};

export default KLPleaseWait;
