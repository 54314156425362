import styled from "styled-components";
import colors from "../../../constants/colors";

export const IconWrapper = styled("div")({
  ".MuiSvgIcon-root": {
    color: colors.RED,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
