import styled from "styled-components";
import colors from "../../constants/colors";

export const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: colors.BACKGROUND,
  maxWidth: "400px",
  width: "400px",
});
