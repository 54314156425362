import { createAsyncThunk } from "@reduxjs/toolkit";
import { VerifyTokenResponse } from "../../../constants/types";
import { tokensService } from "../../../services/tokensService";

export const verifyTokenThunk = createAsyncThunk<
  VerifyTokenResponse,
  string,
  { rejectValue: string }
>("tokens/verifyToken", async (token: string, { rejectWithValue }) => {
  try {
    const response = await tokensService.verifyToken(token);
    if (response.valid && response.payload) {
      return response;
    } else {
      return rejectWithValue(response.message || "Token is invalid");
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to verify token");
  }
});
