import { createAsyncThunk } from "@reduxjs/toolkit";
import { shortLinkService } from "../../../services/shortLinkService";

export const fetchOriginalUrl = createAsyncThunk<
  { originalUrl: string },
  string,
  { rejectValue: string }
>("shortLinks/fetchOriginalUrl", async (shortCode, { rejectWithValue }) => {
  try {
    const response = await shortLinkService.fetchOriginalUrl(shortCode);
    return { originalUrl: response.originalUrl };
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});
