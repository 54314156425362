import axios from "axios";
import { CustomerSlot } from "../constants/types";

class CustomerService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;
  private apiKey = process.env.REACT_APP_API_KEY;

  async checkCustomerSlots(
    businessId: string,
    phone: string,
  ): Promise<CustomerSlot[]> {
    try {
      const response = await axios.get<CustomerSlot[]>(
        `${this.baseApiUrl}/api/${businessId}/slots/customer`,
        {
          params: { phone },
          headers: {
            "x-api-key": this.apiKey,
          },
        },
      );

      return response.data;
    } catch (e) {
      throw new Error("Failed to fetch customer slots");
    }
  }
}

export const customerService = new CustomerService();
