export interface TokenPayload {
  [key: string]: any;
}

export interface VerifyTokenResponse {
  valid: boolean;
  payload?: TokenPayload;
  message?: string;
}

export interface Slot {
  id: string;
  start: string;
  end: string;
}

export interface CustomerSlot extends Slot {
  service: string;
  price: string;
  cancellationPolicy: number;
}

export interface Service {
  description: string;
  price: string;
  duration: number;
  showWithoutPrice: boolean;
  cancellationPolicy?: number;
}

export interface ServiceWithId extends Service {
  id: number;
}

export interface Business {
  id: string;
  ownerName: string;
  businessName: string;
  category: string;
  phone: string;
  email: string;
}

export enum AvailabilityType {
  Weekly = "weekly",
  BiWeekly = "bi_weekly",
  WeeklyRecurring = "weekly_recurring",
}
