export type category =
  | "רופאי משפחה"
  | "רופאי שיניים"
  | "פיזיותרפיסטים"
  | "כירופרקטורים"
  | "פסיכולוגים ומטפלים"
  | "אופטומטריסטים"
  | "מספרות"
  | "מכוני ציפורניים"
  | "מטפלים בעיסוי"
  | "מכוני יופי וטיפוח עור"
  | "מאפרות"
  | "מכוני ספא"
  | "מכוני הסרת שיער בלייזר"
  | "מאמנים אישיים"
  | "מדריכי יוגה"
  | "מכוני פילאטיס"
  | "סטודיו לריקוד"
  | "בתי ספר לאומנויות לחימה"
  | "חדרי כושר קרוספיט"
  | "סטודיו לספינינג"
  | "עורכי דין"
  | "יועצים משפטיים"
  | "נוטריונים"
  | "מגשרים"
  | "יועצי מס"
  | "רואי חשבון"
  | "מורים פרטיים"
  | "מדריכי מוזיקה"
  | "מורי אומנות"
  | "מאמנים אקדמיים"
  | "בתי ספר לשפות"
  | "תספורות וטיפוח גברים"
  | "קיצוץ ועיצוב זקן"
  | "שירות טיולי כלבים"
  | "שירותי רחיצה לחיות מחמד"
  | "שירותי ניקיון"
  | "שירותי אינסטלציה"
  | "חשמלאים"
  | "שירותי תיקונים כלליים"
  | "שירותי גינון"
  | "הדברה"
  | "מעצבי פנים"
  | "צילום אירועים"
  | "צילום מסחרי"
  | "מאמנים לחיים"
  | "מאמני בריאות ותזונה"
  | "מאמנים לקריירה"
  | "הסרת שיער בלייזר"
  | "מדריכי מיינדפולנס ומדיטציה";

export const categoryToMainImage: Record<category, string> = {
  "רופאי משפחה": "family_doctors",
  "רופאי שיניים": "dentists",
  "הסרת שיער בלייזר": "laser_hair_removal",
  פיזיותרפיסטים: "physiotherapists",
  כירופרקטורים: "chiropractors",
  "פסיכולוגים ומטפלים": "psychologists_and_therapists",
  אופטומטריסטים: "optometrists",
  מספרות: "barbershops",
  "מכוני ציפורניים": "nail_salons",
  "מטפלים בעיסוי": "massage_therapists",
  "מכוני הסרת שיער בלייזר": "laser_hair_removal",
  "מכוני יופי וטיפוח עור": "beauty_salons_skin_care",
  מאפרות: "make_up_services",
  "מכוני ספא": "clinic",
  "מאמנים אישיים": "personal_trainers",
  "מדריכי יוגה": "yoga_instructors",
  "מכוני פילאטיס": "pilates_instructors",
  "סטודיו לריקוד": "dance_studios",
  "בתי ספר לאומנויות לחימה": "martial_arts_schools",
  "חדרי כושר קרוספיט": "crossfit_gyms",
  "סטודיו לספינינג": "spinning_studios",
  "עורכי דין": "lawyer_firms",
  "יועצים משפטיים": "mediators",
  נוטריונים: "notary_services",
  מגשרים: "mediators",
  "יועצי מס": "tax_consultants",
  "רואי חשבון": "accountants",
  "מורים פרטיים": "private_tutors",
  "מדריכי מוזיקה": "music_instructors",
  "מורי אומנות": "art_teachers",
  "מאמנים אקדמיים": "academic_instructors",
  "בתי ספר לשפות": "language_schools",
  "תספורות וטיפוח גברים": "barber_shops_for_men",
  "קיצוץ ועיצוב זקן": "mens_beard_trimming",
  "שירות טיולי כלבים": "dog_walkers",
  "שירותי רחיצה לחיות מחמד": "dog_washing_services",
  "שירותי ניקיון": "cleaning_services",
  "שירותי אינסטלציה": "plumbing_services",
  חשמלאים: "electricians",
  "שירותי תיקונים כלליים": "general_repair_services",
  "שירותי גינון": "gardening_services",
  הדברה: "pest_control",
  "מעצבי פנים": "interior_design_services",
  "צילום אירועים": "photography_services",
  "צילום מסחרי": "commercial_photography_services",
  "מאמנים לחיים": "coaching",
  "מאמני בריאות ותזונה": "nutrition_consultants",
  "מאמנים לקריירה": "career_consultants",
  "מדריכי מיינדפולנס ומדיטציה": "meditation_mindfulness_trainers",
};

export default categoryToMainImage;
