import { format } from "date-fns";

export const dayMapping: { [key: string]: string } = {
  Sunday: "ראשון",
  Monday: "שני",
  Tuesday: "שלישי",
  Wednesday: "רביעי",
  Thursday: "חמישי",
  Friday: "שישי",
};

export const daysOfWeek = [
  { value: "ראשון", label: "ראשון", index: 0 },
  { value: "שני", label: "שני", index: 1 },
  { value: "שלישי", label: "שלישי", index: 2 },
  { value: "רביעי", label: "רביעי", index: 3 },
  { value: "חמישי", label: "חמישי", index: 4 },
  { value: "שישי", label: "שישי", index: 5 },
];

export const cancellationOptions = [
  { value: 0, label: "זה בסדר, אפשר לבטל תמיד" },
  { value: 1, label: "עד שעה לפני" },
  { value: 2, label: "עד שעתיים לפני" },
  { value: 6, label: "עד 6 שעות לפני" },
  { value: 12, label: "עד 12 שעות לפני" },
  { value: 24, label: "עד 24 שעות לפני" },
];

export const generateTimeOptions = (
  start: string,
  end: string,
  interval: number,
) => {
  const times = [];
  let currentTime = new Date(`1970-01-01T${start}:00`);
  const endTime = new Date(`1970-01-01T${end}:00`);

  while (currentTime <= endTime) {
    const formattedTime = currentTime.toTimeString().slice(0, 5);
    times.push({ value: formattedTime, label: formattedTime });
    currentTime = new Date(currentTime.getTime() + interval * 60 * 1000);
  }
  return times;
};

export const generateDateDayOptions = (weeksAhead: number) => {
  const options: { value: string; label: string; date: string; day: string }[] =
    [];
  const today = new Date();

  const dayOfWeek = today.getDay();
  const daysUntilSunday = (7 - dayOfWeek) % 7;
  const startDate = new Date(today);
  startDate.setDate(today.getDate() + daysUntilSunday);

  const totalDays = weeksAhead * 7;
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + totalDays - 1);

  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    if (date.getDay() === 6) {
      continue;
    }
    const dayName =
      daysOfWeek.find((d) => d.index === date.getDay())?.value || "";
    const formattedDate = format(date, "dd/MM/yy");
    const value = `${dayName} - ${formattedDate}`;
    options.push({
      value,
      label: value,
      date: date.toISOString().split("T")[0],
      day: dayName,
    });
  }
  return options;
};
