import React from "react";
import { IconWrapper } from "./styles";
import { Delete } from "@mui/icons-material";

const KLTrashCanIcon: React.FC = () => {
  return (
    <IconWrapper>
      <Delete />
    </IconWrapper>
  );
};

export default KLTrashCanIcon;
