import styled from "styled-components";
import colors from "../../constants/colors";

export const Container = styled("div")({
  width: "100%",
  marginBottom: "1.5rem",
});

export const ProgressContainer = styled("div")({
  width: "100%",
  height: "10px",
  display: "flex",
  position: "relative",
  backgroundColor: colors.GREY_5,
  borderRadius: "5px",
  overflow: "hidden",
});

export const ProgressFill = styled("div")<{ $progress: number }>(
  ({ $progress }) => ({
    width: `${$progress}%`,
    height: "100%",
    backgroundColor: colors.PURPLE,
    transition: "width 0.4s ease-in-out",
    borderRadius: "5px 0 0 5px",
  }),
);

export const Divider = styled("div")<{ $position: number }>((props) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: `${props.$position}%`,
  width: "2px",
  backgroundColor: colors.GREY_6,
}));

export const LabelContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  marginBottom: "8px",
});

export const Label = styled("div")({
  flex: 1,
  textAlign: "center",
  fontSize: "11px",
  color: colors.BLACK,
  position: "relative",
});
