import React, { useState } from "react";
import { Input, ErrorText, Counter, InputWrapper } from "./styles";

interface KLTextInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type?: string;
  required?: boolean;
  onValidationChange?: (isValid: boolean) => void;
  disabled?: boolean;
  maxNumOfCharacters?: number;
}

const phoneRegex = /^05\d{8}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const KLTextInput: React.FC<KLTextInputProps> = ({
  value,
  onChange,
  placeholder,
  type = "text",
  required,
  onValidationChange,
  disabled,
  maxNumOfCharacters,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (maxNumOfCharacters && inputValue.length > maxNumOfCharacters) {
      return;
    }

    onChange(e);

    if (required && inputValue === "") {
      onValidationChange?.(false);
      return;
    }

    if (type === "tel") {
      if (/^\d*$/.test(inputValue)) {
        if (inputValue === "" || phoneRegex.test(inputValue)) {
          setError(null);
          onValidationChange?.(true);
        } else {
          setError("טלפון בפורמט 05XXXXXXXX");
          onValidationChange?.(false);
        }
      }
    } else if (type === "email") {
      if (inputValue === "" || emailRegex.test(inputValue)) {
        setError(null);
        onValidationChange?.(true);
      } else {
        setError("someone@example.com");
        onValidationChange?.(false);
      }
    } else {
      onValidationChange?.(true);
    }
  };

  const isMaxReached = maxNumOfCharacters && value.length >= maxNumOfCharacters;

  return (
    <>
      <InputWrapper>
        {maxNumOfCharacters && (
          <Counter $isMaxReached={isMaxReached || false}>
            {maxNumOfCharacters} / {value.length}
          </Counter>
        )}
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          required={required}
          disabled={disabled}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default KLTextInput;
