import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "../../../constants/types";

interface BusinessState {
  ownerName: string;
  businessName: string;
  phone: string;
  email: string;
  category: string | null;
  services: Service[];
}

const initialState: BusinessState = {
  ownerName: "",
  businessName: "",
  phone: "",
  email: "",
  category: null,
  services: [],
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setOwnerName: (state, action: PayloadAction<string>) => {
      state.ownerName = action.payload;
    },
    setBusinessPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setBusinessEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setBusinessCategory: (state, action: PayloadAction<string | null>) => {
      state.category = action.payload;
    },
    setBusinessServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    },
  },
});

export const {
  setBusinessName,
  setOwnerName,
  setBusinessPhone,
  setBusinessEmail,
  setBusinessCategory,
  setBusinessServices,
} = businessSlice.actions;

export default businessSlice.reducer;
