import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checkCustomerSlots, fetchBusiness } from "./thunk";
import {
  Business,
  CustomerSlot,
  ServiceWithId,
} from "../../../constants/types";
import { cancelSlot } from "../slots/thunk";

interface CustomersState {
  customerName: string;
  customerPhone: string;
  customerService: ServiceWithId | null;
  business: Business | null;
  fetchingBusiness: boolean;
  customerSlots: CustomerSlot[];
  fetchingCustomerSlots: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  customerName: "",
  customerPhone: "",
  customerService: null,
  business: null,
  fetchingBusiness: false,
  customerSlots: [],
  fetchingCustomerSlots: false,
  error: null,
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomerName: (state, action: PayloadAction<string>) => {
      state.customerName = action.payload;
    },
    setCustomerPhone: (state, action: PayloadAction<string>) => {
      state.customerPhone = action.payload;
    },
    setCustomerService: (state, action: PayloadAction<ServiceWithId>) => {
      state.customerService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusiness.pending, (state) => {
        state.fetchingBusiness = true;
        state.error = null;
      })
      .addCase(
        fetchBusiness.fulfilled,
        (state, action: PayloadAction<Business>) => {
          state.business = action.payload;
          state.fetchingBusiness = false;
        },
      )
      .addCase(fetchBusiness.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch business";
        state.fetchingBusiness = false;
      })
      .addCase(checkCustomerSlots.pending, (state) => {
        state.fetchingCustomerSlots = true;
      })
      .addCase(
        checkCustomerSlots.fulfilled,
        (state, action: PayloadAction<{ customerSlots: CustomerSlot[] }>) => {
          state.fetchingCustomerSlots = false;
          state.customerSlots = action.payload.customerSlots;
        },
      )
      .addCase(checkCustomerSlots.rejected, (state, action) => {
        state.fetchingCustomerSlots = false;
        state.error = action.payload || "Failed to fetch customer slots";
      })
      .addCase(cancelSlot.fulfilled, (state, action: PayloadAction<string>) => {
        state.customerSlots = state.customerSlots.filter(
          (slot) => slot.id !== action.payload,
        );
      });
  },
});

export const { setCustomerName, setCustomerPhone, setCustomerService } =
  customersSlice.actions;

export default customersSlice.reducer;
